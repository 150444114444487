import React from "react"
import {
  Text,
  Box,
  Image,
  Flex,
  useColorModeValue,
  Button
} from "@chakra-ui/react"

const PostImage = ({ name, image, permalink }) => {
  return (
    <Flex
      bg={useColorModeValue("#F9FAFB", "gray.600")}
      w="40"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        w="xs"
        bg={useColorModeValue("white", "gray.800")}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
        mx="auto"
      >
        <Box position="relative" display="flex" alignItems="start">
          <Text
            w="100%"
            position="absolute"
            textAlign="center"
            backgroundColor="white"
            color="black"
            opacity="0.8"
            p={2}
          >
            {name}
          </Text>

          <Image w="full" h="230px" fit="cover" src={image} alt="avatar" />
        </Box>
        <a href={permalink}>
          <Button
            w="100%"
            colorScheme="green"
            variant="solid"
            borderTopRadius="0"
          >
            دانلود زیرنویس
          </Button>
        </a>
      </Box>
    </Flex>
  )
}

export default PostImage
