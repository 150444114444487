import React, { Component } from "react"
import {
  Box,
  chakra,
  Text,
  Button,
  Avatar,
  Table,
  Tr,
  Tbody,
  Td,
  Flex
} from "@chakra-ui/react"
import InfoCard from "./info.card"
import axios from "axios"
import { Helmet } from "react-helmet"
import { Error404 } from "./../../pages/errors"

export class MainEpisodes extends Component {
  constructor() {
    super()
    this.state = {
      data: {},
      isLoaded: false,
      title: false
    }
  }

  componentDidMount() {
    axios
      .get(
        "https://hangulsub.com/handle/postinfo/" +
          this.props.team +
          "/" +
          this.props.url
      )
      .then((res) => {
        this.setState({ data: res.data, isLoaded: true })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    if (this.state.isLoaded) {
      if (this.state.data.message) {
        if (this.state.data.error === 404) {
          return <Error404 />
        }
      } else {
        return (
          <Box
            display="flex"
            maxWidth="100%"
            mx={{ base: "5", md: "10" }}
            pt="30px"
            flexWrap={{ base: "wrap", md: "nowrap" }}
            flexDirection={{ md: "row-reverse" }}
            pb="20"
          >
            {/* Title */}
            <Helmet>
              <title>
                {"زیرنویس سریال " +
                  this.state.data.post.name +
                  " با ترجمه " +
                  this.state.data.team.team_name}
              </title>
            </Helmet>

            {/* Post Card */}
            <Box w={{ base: "75%", md: "25%" }} mx="auto">
              <InfoCard data={this.state.data.post} />
            </Box>
            <Box
              h="max-content"
              w={{ base: "100%", md: "75%" }}
              mr={{ md: 10 }}
              mt={{ base: 8, md: 0 }}
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
            >
              <Box
                bg={this.props.bg}
                borderRadius="10"
                borderWidth="1px"
                shadow="lg"
                display="flex"
                alignItems="start"
                flexDirection="column"
                width="100%"
                dir="rtl"
                p="5"
              >
                <Text fontWeight="bold">خلاصه داستان:</Text>
                <chakra.p>{this.state.data.post.story}</chakra.p>
              </Box>
              <Box
                bg={this.props.bg}
                borderRadius="10"
                borderWidth="1px"
                shadow="lg"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                dir="rtl"
                p="5"
                mt="5"
              >
                <Flex
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">لینک زیرنویس ها:</Text>

                  {/* Zip Button */}
                  {this.state.data.team.have_zip ? (
                    <a href={this.state.data.team.zip_url}>
                      <Button colorScheme="orange" size="sm">
                        دانلود همه ی قسمت ها
                      </Button>
                    </a>
                  ) : null}
                </Flex>

                <Table variant="striped" colorScheme="green" dir="rtl" mt="5">
                  <Tbody>
                    {/* Links */}
                    {this.state.data.subs.map((sub) => {
                      return (
                        <Tr>
                          <Td>قسمت {sub.episode_number}</Td>
                          <Td isNumeric>
                            <a href={sub.url}>
                              <Button colorScheme="green" size="md">
                                دانلود
                              </Button>
                            </a>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>

                <Box pt="5" w="100%">
                  <a href={this.state.data.team.team_permalink}>
                    <Button colorScheme="green" size="md" h="auto" w="100%">
                      <Box display="flex" alignItems="center" mx="5" my="1">
                        <Avatar
                          ml="5"
                          name={this.state.data.team.team_name}
                          src={this.state.data.team.team_image}
                        />
                        <Text dir="rtl" fontWeight="bold">
                          مشاهده همه ترجمه های {this.state.data.team.team_name}
                        </Text>
                      </Box>
                    </Button>
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }
    } else {
      return <Box></Box>
    }
  }
}

export default MainEpisodes
