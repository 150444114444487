import { useToast, Button } from "@chakra-ui/react"
export default function LoginButton() {
  const toast = useToast()
  return (
    <Button
      variant="ghost"
      onClick={() =>
        toast({
          title: "این بخش هنوز حاضر نیست",
          status: "info",
          duration: 5000,
          //   isClosable: true,
          position: "bottom"
        })
      }
    >
      ورود
    </Button>
  )
}
