import React from "react"
import { Box, Button, Text, useColorModeValue } from "@chakra-ui/react"
import {
  BsFillFileEarmarkZipFill,
  BsFillFileEarmarkMedicalFill
} from "react-icons/bs"
const DownloadBox = ({ name, srtUrl, zipUrl }) => {
  const bg = useColorModeValue("white", "gray.800")
  return (
    <Box
      bg={bg}
      borderRadius="10"
      borderWidth="1px"
      shadow="lg"
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="max-content"
      px="5"
      m="3"
    >
      <Text dir="rtl" mt="5" fontWeight="700">
        {name}
      </Text>
      {srtUrl ? (
        <a href={srtUrl}>
          <Button
            backgroundColor="#27AE60"
            colorScheme="teal"
            color="white"
            leftIcon={<BsFillFileEarmarkMedicalFill />}
            mt="3"
            size="md"
            dir="rtl"
          >
            دریافت نسخه SRT
          </Button>
        </a>
      ) : null}

      {zipUrl ? (
        <a href={zipUrl}>
          <Button
            backgroundColor="#E74C3C"
            colorScheme="teal"
            color="white"
            leftIcon={<BsFillFileEarmarkZipFill />}
            mt="3"
            mb="5"
            size="md"
            dir="rtl"
          >
            دریافت نسخه ZIP
          </Button>
        </a>
      ) : null}
    </Box>
  )
}

export default DownloadBox
