import { useColorModeValue } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import MainEpisodes from "./main.episodes"

const Download = () => {
  let { team, url } = useParams()
  const bg = useColorModeValue("white", "gray.800")
  return <MainEpisodes bg={bg} team={team} url={url} />
}

export default Download
