import React, { Component } from "react"
import axios from "axios"
import TeamInfo from "./team.info"
import PostImage from "./post.image"
import Pagination from "./pagination"
import { Box, Wrap, Text, WrapItem } from "@chakra-ui/react"
import { Helmet } from "react-helmet"
import { Error404 } from "./../../pages/errors"

export class MainTeam extends Component {
  constructor() {
    super()
    this.state = {
      data: {},
      isLoaded: false
    }
  }

  componentDidMount() {
    axios
      .get(
        "https://hangulsub.com/handle/teaminfo/" +
          this.props.team +
          "/" +
          this.props.page
      )
      .then((res) => {
        this.setState({ data: res.data, isLoaded: true })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  render() {
    if (this.state.isLoaded) {
      if (this.state.data.message) {
        if (this.state.data.error === 404) {
          return <Error404 />
        }
      } else {
        return (
          <Box
            display="flex"
            maxWidth="100%"
            mx={{ base: "5", md: "10" }}
            pt="30px"
            flexWrap={{ base: "wrap", md: "nowrap" }}
            flexDirection={{ md: "row-reverse" }}
            pb="20"
          >
            {/* Title */}
            <Helmet>
              <title>{"تیم ترجمه " + this.state.data.name}</title>
            </Helmet>

            {/* Team Info */}
            <Box w={{ base: "75%", md: "25%" }} mx="auto" mt="0">
              <TeamInfo data={this.state.data} />
            </Box>
            <Box
              h="max-content"
              w={{ base: "100%", md: "75%" }}
              mr={{ md: 10 }}
              mt={{ base: 8, md: 0 }}
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
            >
              <Box
                bg={this.props.bg}
                borderRadius="10"
                borderWidth="1px"
                shadow="lg"
                display="flex"
                alignItems="center"
                flexDirection="column"
                width="100%"
                dir="rtl"
                p="5"
              >
                <Text fontWeight="bold">لیست سریال های ترجمه شده:</Text>

                {/* List Of Series */}
                <Wrap justify="center" mt="5" spacing="5">
                  {this.state.data.subs.map((sub, index) => {
                    return (
                      <WrapItem key={index}>
                        <PostImage
                          name={sub.name}
                          image={sub.image}
                          permalink={sub.permalink}
                        />
                      </WrapItem>
                    )
                  })}
                </Wrap>

                {/* Pagination */}

                <Pagination
                  data={this.state.data}
                  currentPage={this.props.page}
                />
              </Box>
            </Box>
          </Box>
        )
      }
    } else {
      return <Box></Box>
    }
  }
}

export default MainTeam
