import React from "react"
import DownloadBox from "./download.box"
import { CopyRightAlert, ExpireAlert } from "./alerts"
import { Box, Button, Text, Avatar } from "@chakra-ui/react"
import axios from "axios"
import TVCard from "../global/sub.card"
import { Helmet } from "react-helmet"
import { Error404, Error406 } from "./../../pages/errors"

export class DownloadMiddle extends React.Component {
  constructor() {
    super()
    this.state = {
      data: {},
      isLoaded: false
    }
  }

  componentDidMount() {
    axios
      .get("https://hangulsub.com/handle/subdownload/" + this.props.url)
      .then((res) => {
        this.setState({ data: res.data, isLoaded: true })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    if (this.state.isLoaded) {
      if (this.state.data.message) {
        if (this.state.data.error === 404) {
          return <Error404 />
        } else if (this.state.data.error === 406) {
          return <Error406 />
        }
      } else {
        return (
          <Box
            display="flex"
            maxWidth="100%"
            mx={{ base: "5", md: "10" }}
            pt="30px"
            flexWrap={{ base: "wrap", md: "nowrap" }}
            flexDirection={{ md: "row-reverse" }}
            pb="20"
          >
            {/* Title */}
            <Helmet>
              <title>
                {"لینک دانلود " +
                  this.state.data.post_name +
                  " " +
                  this.state.data.episode_text}
              </title>
            </Helmet>

            {/* Post Card Box */}
            <Box w={{ base: "75%", md: "25%" }} m="auto" mt="0">
              <TVCard
                image={this.state.data.post_image}
                episode={this.state.data.episode_text}
                name={this.state.data.post_name}
                url={this.state.data.post_permalink}
              />
            </Box>

            <Box
              h="max-content"
              w={{ base: "100%", md: "75%" }}
              mr={{ md: 10 }}
              mt={{ base: 8, md: 0 }}
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
            >
              <ExpireAlert />

              {/* Download Box */}
              {this.state.data.subs.map((sub, index) => (
                <DownloadBox
                  name={sub.quality}
                  srtUrl={sub.srt_url}
                  zipUrl={sub.zip_url}
                  key={index}
                />
              ))}

              <CopyRightAlert />

              <Box display="flex" justifyContent="center" w="100%">
                <a href={this.state.data.team_permalink}>
                  <Button
                    colorScheme="green"
                    size="md"
                    h="auto"
                    mt={5}
                    dir="rtl"
                  >
                    <Box display="flex" alignItems="center" mx="5" my="1">
                      <Avatar
                        ml="5"
                        name={this.state.data.team_name}
                        src={this.state.data.team_image}
                      />
                      <Text dir="rtl" fontWeight="bold">
                        مشاهده همه ترجمه های {this.state.data.team_name}
                      </Text>
                    </Box>
                  </Button>
                </a>
              </Box>
            </Box>
          </Box>
        )
      }
    } else {
      return <Box></Box>
    }
  }
}

export default DownloadMiddle
