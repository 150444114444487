import React from "react"

import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
  Image
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import LoginButton from "./loginButton"
import { AiOutlineMenu } from "react-icons/ai"
import logo from "./../../assets/hsublogo.png"

export default function Header() {
  const bg = useColorModeValue("white", "gray.800")
  const mobileNav = useDisclosure()
  return (
    <React.Fragment>
      <chakra.header
        bg={bg}
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="lg"
        position="fixed"
        top="0"
        zIndex="10"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="brand.500"
              display={{ base: "none", md: "inline-flex" }}
            >
              <ColorModeSwitcher justifySelf="flex-start" />
              <a href="/contact">
                <Button colorScheme="green">ارتباط با ما</Button>
              </a>
              <LoginButton />
            </HStack>
            <Box display={{ base: "inline-flex", md: "none" }}>
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={mobileNav.onOpen}
              />
              <ColorModeSwitcher justifySelf="flex-start" />

              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? "flex" : "none"}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={bg}
                spacing={3}
                rounded="sm"
                shadow="sm"
              >
                <CloseButton
                  aria-label="Close menu"
                  onClick={mobileNav.onClose}
                />

                <a href="/contact">
                  <Button w="full" variant="ghost">
                    ارتباط با ما
                  </Button>
                </a>

                <LoginButton />
              </VStack>
            </Box>
          </HStack>
          <Flex>
            <chakra.a h="40px" href="/" display="flex" alignItems="center">
              <chakra.h1 fontSize="xl" fontWeight="900" ml="2">
                هانگول ساب
              </chakra.h1>
              <Image src={logo} height="100%" pl="10px" />
            </chakra.a>
          </Flex>
        </Flex>
      </chakra.header>
    </React.Fragment>
  )
}
