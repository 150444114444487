import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "iranyekan",
    body: "iranyekan"
  },
  direction: "rtl"
})

export default theme
