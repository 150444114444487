import { useColorModeValue } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import MainTeam from "./main.team.jsx"

const TeamProfile = () => {
  let { team, page } = useParams()
  if (!page) {
    page = 1
  }

  const bg = useColorModeValue("white", "gray.800")
  return <MainTeam team={team} page={page} bg={bg} />
}

export default TeamProfile
