import React from "react"
import { Box, Flex, chakra, useColorModeValue } from "@chakra-ui/react"

const TVCard = ({ image, name, episode, url }) => {
  return (
    <Flex
      //   bg={useColorModeValue("#F9FAFB", "gray.600")}
      //   p={50}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        w="sm"
        mx="auto"
      >
        <Box
          bg="gray.300"
          h={80}
          w="full"
          rounded="lg"
          shadow="md"
          bgSize="cover"
          //   bgPos="center"
          style={{
            backgroundImage: "url(" + image + ")"
          }}
        ></Box>

        <Box
          w={{ base: 56, md: 64 }}
          bg={useColorModeValue("white", "gray.800")}
          mt={-10}
          shadow="lg"
          rounded="lg"
          overflow="hidden"
        >
          <chakra.h3
            py={2}
            px={1}
            textAlign="center"
            fontWeight="bold"
            // textTransform="uppercase"
            color={useColorModeValue("gray.800", "white")}
            letterSpacing={1}
          >
            {name}
          </chakra.h3>

          <Flex
            alignItems="center"
            justifyContent="center"
            py={2}
            px={3}
            bg={useColorModeValue("gray.200", "gray.700")}
          >
            <chakra.div
              backgroundColor="#F5B041"
              fontSize="xs"
              fontWeight="bold"
              color="white"
              px={2}
              py={1}
              rounded="lg"
            >
              {episode}
            </chakra.div>
          </Flex>

          <a href={url}>
            <chakra.button
              bg="#00E391"
              fontSize="sm"
              fontWeight="bold"
              color="white"
              w="100%"
              px={2}
              py={1}
              // rounded="lg"
              textTransform="uppercase"
              _hover={{
                bg: useColorModeValue("#27AE60", "#27AE60")
              }}
              _focus={{
                bg: useColorModeValue("#27AE60", "#27AE60"),
                outline: "none"
              }}
            >
              مشاهده همه ی قسمت ها
            </chakra.button>
          </a>
        </Box>
      </Flex>
    </Flex>
  )
}

export default TVCard
