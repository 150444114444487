import React from "react"
import { Box, Image, Text } from "@chakra-ui/react"
import { Helmet } from "react-helmet"

import img from "./../assets/404.svg"
import img2 from "./../assets/406.svg"
export const Error404 = () => {
  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap={{ base: "wrap", md: "nowrap" }}
        maxWidth="100%"
        mx={{ base: "5", md: "20" }}
        pt="60px"
      >
        <Helmet>
          <title>صفحه مورد نظر پیدا نشد</title>
        </Helmet>

        <Image w={{ base: "100%", md: "50%" }} pb={{ base: 10 }} src={img} />
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          h="max"
          m="auto"
        >
          <Text
            dir="rtl"
            fontWeight="700"
            fontSize={{ base: "20", md: "30" }}
            textAlign="center"
          >
            صفحه مورد نظر پیدا نشد :(
          </Text>
          <Text
            dir="rtl"
            color="grey"
            fontWeight="300"
            fontSize={{ base: "15", md: "20" }}
            textAlign="center"
          >
            آدرسی که وارد کردی وجود نداره یا منقضی شده
          </Text>
        </Box>
      </Box>
    </div>
  )
}

export const Error406 = () => {
  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap={{ base: "wrap", md: "nowrap" }}
        maxWidth="100%"
        mx={{ base: "5", md: "20" }}
        pt="60px"
      >
        <Helmet>
          <title>اجازه دانلود نداری</title>
        </Helmet>

        <Image w={{ base: "100%", md: "40%" }} pb={{ base: 10 }} src={img2} />
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          h="max"
          m="auto"
        >
          <Text
            dir="rtl"
            fontWeight="700"
            fontSize={{ base: "20", md: "30" }}
            textAlign="center"
          >
            عجیبه امروز خیلی بیشتر از حد معمول دانلود کردی
          </Text>
          <Text
            dir="rtl"
            color="grey"
            fontWeight="300"
            fontSize={{ base: "15", md: "20" }}
            textAlign="center"
          >
            بخاطر دلایل امنیتی مجبوریم دسترسیتو تا 24 ساعت قطع کنیم (سعی کن
            آرشیومون رو کپی نکنی چون میفهمیم)
          </Text>
        </Box>
      </Box>
    </div>
  )
}
