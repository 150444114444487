import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: iranyekan;
        font-style: normal;
        font-weight: bold;
        font-display: fallback;
        src: url('/fonts/eot/iranyekanwebbold.eot');
        src: url('/fonts/eot/iranyekanwebbold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
             url('/fonts/woff/iranyekanwebbold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
             url('/fonts/woff2/iranyekanwebbold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
             url('/fonts/ttf/iranyekanwebbold.ttf') format('truetype');
    }
    
    @font-face {
        font-family: iranyekan;
        font-style: normal;
        font-weight: 100;
        font-display: fallback;
        src: url('/fonts/eot/iranyekanwebthin.eot');
        src: url('/fonts/eot/iranyekanwebthin.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
             url('/fonts/woff/iranyekanwebthin.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
             url('/fonts/woff2/iranyekanwebthin.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
             url('/fonts/ttf/iranyekanwebthin.ttf') format('truetype');
    }
    
    @font-face {
        font-family: iranyekan;
        font-style: normal;
        font-weight: 300;
        font-display: fallback;
        src: url('/fonts/eot/iranyekanweblight.eot');
        src: url('/fonts/eot/iranyekanweblight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
             url('/fonts/woff/iranyekanweblight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
             url('/fonts/woff2/iranyekanweblight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
             url('/fonts/ttf/iranyekanweblight.ttf') format('truetype');
    }
    
    @font-face {
        font-family: iranyekan;
        font-style: normal;
        font-weight: normal;
        font-display: fallback;
        src: url('/fonts/eot/iranyekanwebregular.eot');
        src: url('/fonts/eot/iranyekanwebregular.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
             url('/fonts/woff/iranyekanwebregular.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
             url('/fonts/woff2/iranyekanwebregular.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
             url('/fonts/ttf/iranyekanwebregular.ttf') format('truetype');
    }
    
    @font-face {
        font-family: iranyekan;
        font-style: normal;
        font-weight: 500;
        font-display: fallback;
        src: url('/fonts/eot/iranyekanwebmedium.eot');
        src: url('/fonts/eot/iranyekanwebmedium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
             url('/fonts/woff/iranyekanwebmedium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
             url('/fonts/woff2/iranyekanwebmedium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
             url('/fonts/ttf/iranyekanwebmedium.ttf') format('truetype');
    }
    
    @font-face {
        font-family: iranyekan;
        font-style: normal;
        font-weight: 800;
        font-display: fallback;
        src: url('/fonts/eot/iranyekanwebextrabold.eot');
        src: url('/fonts/eot/iranyekanwebextrabold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
             url('/fonts/woff/iranyekanwebextrabold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
             url('/fonts/woff2/iranyekanwebextrabold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
             url('/fonts/ttf/iranyekanwebextrabold.ttf') format('truetype');
    }
    
    @font-face {
        font-family: iranyekan;
        font-style: normal;
        font-weight: 850;
        font-display: fallback;
        src: url('/fonts/eot/iranyekanwebblack.eot');
        src: url('/fonts/eot/iranyekanwebblack.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
             url('/fonts/woff/iranyekanwebblack.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
             url('/fonts/woff2/iranyekanwebblack.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
             url('/fonts/ttf/iranyekanwebblack.ttf') format('truetype');
    }
    
    @font-face {
        font-family: iranyekan;
        font-style: normal;
        font-weight: 900;
        font-display: fallback;
        src: url('/fonts/eot/iranyekanwebextrablack.eot');
        src: url('/fonts/eot/iranyekanwebextrablack.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
             url('/fonts/woff/iranyekanwebextrablack.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
             url('/fonts/woff2/iranyekanwebextrablack.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
             url('/fonts/ttf/iranyekanwebextrablack.ttf') format('truetype');
    }
      `}
  />
)

export default Fonts
