import React from "react"
import { MdTimer } from "react-icons/md"
import { GoAlert } from "react-icons/go"
import { Alert, Text } from "@chakra-ui/react"

export function CopyRightAlert() {
  return (
    <Alert
      dir="rtl"
      status="success"
      borderRadius="xl"
      backgroundColor="#8E44AD"
      color="white"
      display="flex"
      justifyContent="center"
    >
      <GoAlert fontSize="25" />
      <Text mr="2">تمام حقوق این زیرنویس متعلق به تیم ترجمه است.</Text>
    </Alert>
  )
}

export function ExpireAlert() {
  return (
    <Alert
      dir="rtl"
      status="success"
      borderRadius="xl"
      backgroundColor="#28B463"
      color="white"
      display="flex"
      justifyContent="center"
    >
      <MdTimer fontSize="25" />
      <Text mr="2">لینک دانلود تا 12 ساعت برای شما فعال میباشد!</Text>
    </Alert>
  )
}
