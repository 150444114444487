import React from "react"
import {
  Box,
  Image,
  Flex,
  Icon,
  useColorModeValue,
  Text
} from "@chakra-ui/react"

import { FaTheaterMasks } from "react-icons/fa"
import { BsFillCalendarCheckFill } from "react-icons/bs"

const InfoCard = ({ data }) => {
  return (
    <Flex
      bg={useColorModeValue("#F9FAFB", "gray.600")}
      //   p={50}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        w="sm"
        mx="auto"
        bg={useColorModeValue("white", "gray.800")}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
      >
        <Image
          w="full"
          h={80}
          fit="cover"
          objectPosition="top"
          src={data.image}
          alt="avatar"
        />

        <Flex
          justifyContent="center"
          alignItems="center"
          px={6}
          py={3}
          bg="gray.900"
          borderBottomEndRadius="md"
          borderBottomStartRadius="lg"
        >
          <Text fontWeight="bold" color="white">
            {data.name}
          </Text>
        </Flex>

        <Box py={4} px={6} dir="rtl">
          <Flex
            alignItems="center"
            mt={4}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            <Icon as={FaTheaterMasks} h={6} w={6} mr={2} />

            <Text mr="3" ml="2" fontWeight="bold">
              ژانر:
            </Text>
            <Text>{data.genres}</Text>
          </Flex>
          <Flex
            alignItems="center"
            mt={4}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            <Icon as={BsFillCalendarCheckFill} h={6} w={6} mr={2} />

            <Text mr="3" ml="2" fontWeight="bold">
              سال انتشار:
            </Text>
            <Text>{data.year}</Text>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default InfoCard
