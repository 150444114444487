import React from "react"
import Header from "./components/navbar"
import Episodes from "./components/page.episodes"
import Download from "./components/page.download"
import Sub from "./components/page.sub"
import TeamProfile from "./components/page.team"

import Home from "./pages/home"
import { Error404 } from "./pages/errors"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Box } from "@chakra-ui/react"
import ContactUs from "./components/contact"
export default function Gslr() {
  return (
    <BrowserRouter>
      <Box>
        <Header />
        <Box pt="80px" w="100%">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/404" exact element={<Error404 />} />

            <Route path="/sub/:url" exact element={<Sub />} />
            <Route path="/download/:url" exact element={<Download />} />

            <Route path="/teams/:team/:page" exact element={<TeamProfile />} />
            <Route path="/teams/:team/" exact element={<TeamProfile />} />

            <Route path="/posts/:team/:url" exact element={<Episodes />} />

            <Route path="/contact" exact element={<ContactUs />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  )
}
