import React from "react"

import { useParams } from "react-router-dom"
import DownloadMiddle from "./main.download"

const Download = () => {
  const { url } = useParams()

  return <DownloadMiddle url={url} />
}

export default Download
