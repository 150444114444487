import React, { Component } from "react"
import { Box, Button, Text, Avatar } from "@chakra-ui/react"
import { BsFillCloudDownloadFill } from "react-icons/bs"
import TVCard from "../global/sub.card"
import axios from "axios"
import { Helmet } from "react-helmet"
import { Error404 } from "./../../pages/errors"

export class MainSub extends Component {
  constructor() {
    super()
    this.state = {
      data: {},
      isLoaded: false
    }
  }

  componentDidMount() {
    axios
      .get("https://hangulsub.com/handle/subinfo/" + this.props.url)
      .then((res) => {
        this.setState({ data: res.data, isLoaded: true })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  render() {
    if (this.state.isLoaded) {
      if (this.state.data.message) {
        if (this.state.data.error === 404) {
          return <Error404 />
        }
      } else {
        return (
          <Box
            display="flex"
            maxWidth="100%"
            mx={{ base: "5", md: "10" }}
            pt="30px"
            flexWrap={{ base: "wrap", md: "nowrap" }}
            flexDirection={{ base: "column-reverse", md: "row" }}
            pb="20"
          >
            {/* Title */}
            <Helmet>
              <title>
                {"لینک دانلود " +
                  this.state.data.post_name +
                  " " +
                  this.state.data.episode_text}
              </title>
            </Helmet>

            <Box
              h="auto"
              w={{ base: "100%", md: "75%" }}
              mr={{ md: 10 }}
              mt={{ base: 8, md: 0 }}
            >
              <Box
                bg={this.props.bg}
                borderRadius="10"
                borderWidth="1px"
                shadow="lg"
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                {/* Link */}
                <a href={this.state.data.final_url}>
                  <Button
                    backgroundColor="#27AE60"
                    colorScheme="teal"
                    color="white"
                    rightIcon={<BsFillCloudDownloadFill />}
                    my="5"
                    size="lg"
                  >
                    دریافت لینک دانلود
                  </Button>
                </a>

                {/* Button */}
                <Box display="flex" alignItems="center">
                  <Text dir="rtl" fontWeight="bold">
                    ترجمه توسط : {this.state.data.team_name}
                  </Text>
                  <Avatar
                    ml="5"
                    name={this.state.data.team_name}
                    src={this.state.data.team_image}
                  />
                </Box>
                <a href={this.state.data.team_permalink}>
                  <Button colorScheme="pink" my="2" size="sm">
                    صفحه پروفایل مترجم
                  </Button>
                </a>
              </Box>
            </Box>

            {/* Post Card */}
            <Box w={{ base: "75%", md: "25%" }} m="auto" mt="0">
              <TVCard
                image={this.state.data.post_image}
                episode={this.state.data.episode_text}
                name={this.state.data.post_name}
                url={this.state.data.post_permalink}
              />
            </Box>
          </Box>
        )
      }
    } else {
      return <Box></Box>
    }
  }
}

export default MainSub
