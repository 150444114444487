import React, { Component } from "react"
import {
  chakra,
  Box,
  SimpleGrid,
  GridItem,
  Heading,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Alert,
  AlertIcon
} from "@chakra-ui/react"
import axios from "axios"

export class ContactUs extends Component {
  constructor() {
    super()
    this.state = {
      input: {
        name: "",
        telegram: "",
        email: "",
        text: ""
      },
      alert: ""
    }
  }

  onNameChange = (event) => {
    let { input } = this.state
    input.name = event.target.value
    this.setState({ input })
    console.log(this.state)
  }
  onTelegramChange = (event) => {
    let { input } = this.state
    input.telegram = event.target.value
    this.setState({ input })
    console.log(this.state)
  }
  onEmailChange = (event) => {
    let { input } = this.state
    input.email = event.target.value
    this.setState({ input })
    console.log(this.state)
  }
  onTextChange = (event) => {
    let { input } = this.state
    input.text = event.target.value
    this.setState({ input })
    console.log(this.state)
  }
  onSubmit = () => {
    const { name, email, telegram, text } = this.state.input
    if (name === "" || email === "" || telegram === "" || text === "") {
      this.setState({ alert: "missing" })
      return
    }
    axios
      .post("https://hangulsub.com/handle/contact/", {
        name,
        email,
        telegram,
        text
      })
      .then((res) => {
        this.setState({ data: res.data, isLoaded: true })
      })
      .catch((err) => {
        console.log(err)
      })

    this.setState({
      alert: "success",
      input: { name: "", email: "", telegram: "", text: "" }
    })
    return
  }
  render() {
    return (
      <Box p={10}>
        <Box mt={[10, 0]}>
          <SimpleGrid
            display={{ base: "initial", md: "grid" }}
            columns={{ md: 3 }}
            spacing={{ md: 6 }}
            dir="rtl"
          >
            <GridItem colSpan={{ md: 1 }}>
              <Box px={[4, 0]}>
                <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
                  ارتباط با ما
                </Heading>
                <Text mt={1} fontSize="sm">
                  برای ارتباط با ما از این فرم استفاده کنید
                </Text>
              </Box>
            </GridItem>
            <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
              {this.state.alert === "missing" ? (
                <Alert status="error">
                  <AlertIcon />
                  لطفا همه ی فیلد هارو پر کنید
                </Alert>
              ) : this.state.alert === "success" ? (
                <Alert status="success">
                  <AlertIcon />
                  پیام شما ارسال شد
                </Alert>
              ) : null}

              <chakra.form
                shadow="base"
                rounded={[null, "md"]}
                overflow={{ sm: "hidden" }}
              >
                <Stack px={4} py={5} p={[null, 6]} spacing={6}>
                  <SimpleGrid columns={6} spacing={6}>
                    <FormControl as={GridItem} colSpan={[6, 3]}>
                      <FormLabel
                        htmlFor="first_name"
                        fontSize="sm"
                        fontWeight="md"
                      >
                        نام
                      </FormLabel>
                      <Input
                        type="text"
                        name="first_name"
                        id="first_name"
                        autoComplete="given-name"
                        mt={1}
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md"
                        required
                        onChange={this.onNameChange}
                        value={this.state.input.name}
                      />
                    </FormControl>

                    <FormControl as={GridItem} colSpan={[6, 3]}>
                      <FormLabel
                        htmlFor="last_name"
                        fontSize="sm"
                        fontWeight="md"
                      >
                        آیدی تلگرام
                      </FormLabel>
                      <Input
                        type="text"
                        name="telegram"
                        id="telegram"
                        autoComplete="family-name"
                        mt={1}
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md"
                        required
                        onChange={this.onTelegramChange}
                        value={this.state.input.telegram}
                      />
                    </FormControl>

                    <FormControl as={GridItem} colSpan={[6, 4]}>
                      <FormLabel
                        htmlFor="email_address"
                        fontSize="sm"
                        fontWeight="md"
                      >
                        آدرس ایمیل
                      </FormLabel>
                      <Input
                        type="text"
                        name="email_address"
                        id="email_address"
                        autoComplete="email"
                        mt={1}
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md"
                        required
                        onChange={this.onEmailChange}
                        value={this.state.input.email}
                      />
                    </FormControl>
                  </SimpleGrid>
                  <div>
                    <FormControl id="email" mt={1}>
                      <FormLabel fontSize="sm" fontWeight="md">
                        پیام
                      </FormLabel>
                      <Textarea
                        value={this.state.input.text}
                        mt={1}
                        rows={3}
                        shadow="sm"
                        focusBorderColor="brand.400"
                        fontSize={{ sm: "sm" }}
                        required
                        onChange={this.onTextChange}
                      />
                    </FormControl>
                  </div>
                </Stack>
                <Box px={{ base: 4, sm: 6 }} py={3} textAlign="center">
                  <Button
                    colorScheme="teal"
                    _focus={{ shadow: "" }}
                    fontWeight="md"
                    onClick={this.onSubmit}
                  >
                    ارسال
                  </Button>
                </Box>
              </chakra.form>
            </GridItem>
          </SimpleGrid>
        </Box>
      </Box>
    )
  }
}

export default ContactUs
