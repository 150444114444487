import React from "react"
import { Box, Image, Text } from "@chakra-ui/react"
import { Helmet } from "react-helmet"
import img from "./../assets/welcome.svg"
const Home = () => {
  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap={{ base: "wrap", md: "nowrap" }}
        maxWidth="100%"
        mx={{ base: "5", md: "20" }}
        pt="60px"
      >
        <Helmet>
          <title>صفحه اصلی هانگول ساب</title>
        </Helmet>

        <Image w={{ base: "100%", md: "50%" }} pb={{ base: 10 }} src={img} />
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          h="max"
          m="auto"
        >
          <Text
            dir="rtl"
            fontWeight="700"
            fontSize={{ base: "20", md: "30" }}
            textAlign="center"
          >
            به هانگول ساب ، اولین آپلودسنتر مخصوص زیرنویس خوش اومدی :)
          </Text>
          <Text
            dir="rtl"
            color="grey"
            fontWeight="300"
            fontSize={{ base: "15", md: "20" }}
            textAlign="center"
          >
            اینجا صفحه اصلیه ولی هنوز حاضر نشده!
          </Text>
        </Box>
      </Box>
    </div>
  )
}

export default Home
