import React from "react"
import { chakra, Flex, useColorModeValue, Icon } from "@chakra-ui/react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

const Pagination = ({ data, currentPage }) => {
  const PagButton = (props) => {
    const activeStyle = {
      bg: useColorModeValue("brand.600", "brand.500"),
      color: useColorModeValue("black", "white")
    }
    return (
      <a href={props.link}>
        <chakra.button
          mx={1}
          px={4}
          py={2}
          rounded="md"
          bg={useColorModeValue("gray.200", "gray.700")}
          color={useColorModeValue("gray.700", "gray.200")}
          opacity={props.disabled && 0.6}
          _hover={!props.disabled && activeStyle}
          cursor={props.disabled && "not-allowed"}
          {...(props.active && activeStyle)}
          // display={props.p && !props.active && { base: "none", sm: "block" }}
          alignItems="center"
          display="flex"
        >
          {props.children}
        </chakra.button>
      </a>
    )
  }

  const ButtonList = () => {
    let array = []
    for (let page = 1; page <= data.pages; page++) {
      if (parseInt(currentPage) === parseInt(page)) {
        array.push(
          <PagButton
            p
            active
            key={page}
            link={`/teams/` + data.permalink + "/" + page}
          >
            {page}
          </PagButton>
        )
      } else {
        array.push(
          <PagButton
            p
            key={page}
            link={`/teams/` + data.permalink + "/" + page}
          >
            {page}
          </PagButton>
        )
      }
    }
    return array
  }

  return (
    <Flex pt="5" w="full" alignItems="center" justifyContent="center">
      <Flex flexWrap="wrap">
        {parseInt(currentPage) !== 1 ? (
          <PagButton
            link={
              `/teams/` + data.permalink + "/" + (parseInt(currentPage) - 1)
            }
          >
            <Icon
              as={IoIosArrowForward}
              // color={useColorModeValue("gray.700", "gray.200")}
              boxSize={4}
            />
          </PagButton>
        ) : null}

        <ButtonList />

        {parseInt(currentPage) !== parseInt(data.pages) ? (
          <PagButton
            link={
              `/teams/` + data.permalink + "/" + (parseInt(currentPage) + 1)
            }
          >
            <Icon
              as={IoIosArrowBack}
              // color={useColorModeValue("gray.700", "gray.200")}
              boxSize={4}
            />
          </PagButton>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default Pagination
