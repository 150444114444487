import React from "react"
import { useColorModeValue } from "@chakra-ui/react"
import { useParams } from "react-router-dom"

import MainSub from "./main.sub"
const Sub = () => {
  const { url } = useParams()

  const bg = useColorModeValue("white", "gray.800")

  return <MainSub bg={bg} url={url} />
}

export default Sub
