import React from "react"
import {
  chakra,
  Box,
  Image,
  Flex,
  useColorModeValue,
  Icon,
  Text,
  Button
} from "@chakra-ui/react"

import {
  BsFillCalendarDateFill,
  BsFillFileEarmarkMinusFill,
  BsTelegram,
  BsInstagram
} from "react-icons/bs"

import { BiCameraMovie } from "react-icons/bi"

const TeamInfo = ({ data }) => {
  return (
    <Box>
      <Flex
        bg={useColorModeValue("#F9FAFB", "gray.600")}
        w="full"
        alignItems="center"
        justifyContent="center"
        dir="rtl"
        mt="10"
      >
        <Box
          w="md"
          mx="auto"
          py={4}
          px={8}
          bg={useColorModeValue("white", "gray.800")}
          shadow="lg"
          rounded="lg"
        >
          <Flex justifyContent={{ base: "center", md: "center" }} mt={-16}>
            <Image
              w="90px"
              h="90px"
              fit="cover"
              rounded="full"
              borderStyle="solid"
              borderWidth={2}
              borderColor={useColorModeValue("brand.500", "brand.400")}
              alt="Testimonial avatar"
              src={data.image}
            />
          </Flex>

          <chakra.h2
            color={useColorModeValue("gray.800", "white")}
            fontSize={{ base: "2xl", md: "3xl" }}
            mt={{ base: 2, md: 0 }}
            fontWeight="bold"
            textAlign="center"
          >
            {data.name}
          </chakra.h2>

          <chakra.p
            textAlign="center"
            mt={2}
            color={useColorModeValue("gray.600", "gray.200")}
          >
            درباره تیم ترجمه
          </chakra.p>

          <Flex
            justifyContent="start"
            alignItems="center"
            mt={4}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            <Icon as={BsFillCalendarDateFill} h={6} w={6} mr={2} />

            <Text mr="3" ml="2" fontWeight="bold">
              سال تاسیس:
            </Text>
            <Text>{data.creation}</Text>
          </Flex>

          <Flex
            justifyContent="start"
            alignItems="center"
            mt={4}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            <Icon as={BiCameraMovie} h={6} w={6} mr={2} />

            <Text mr="3" ml="2" fontWeight="bold">
              تعداد سریال ها:
            </Text>
            <Text>{data.post_count}</Text>
          </Flex>

          <Flex
            justifyContent="start"
            alignItems="center"
            mt={4}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            <Icon as={BsFillFileEarmarkMinusFill} h={6} w={6} mr={2} />

            <Text mr="3" ml="2" fontWeight="bold">
              تعداد قسمت ها:
            </Text>
            <Text>{data.sub_count}</Text>
          </Flex>
        </Box>
      </Flex>
      {data.telegram ? (
        <a href={data.telegram}>
          <Button
            rightIcon={<BsTelegram />}
            colorScheme="blue"
            variant="solid"
            mt="5"
            w="full"
          >
            تلگرام
          </Button>
        </a>
      ) : null}

      {data.instagram ? (
        <a href={data.instagram}>
          <Button
            rightIcon={<BsInstagram />}
            colorScheme="pink"
            variant="solid"
            mt="5"
            w="full"
          >
            اینستاگرام
          </Button>
        </a>
      ) : null}
    </Box>
  )
}

export default TeamInfo
